export default function init({model, manager}) {
    function getObserver(callback) {
        const options = {
            root: null, // document
            rootMargin: '0px',
            threshold: [0.15]
        }

        function handler(entries, observer) {
            const intersections = entries.map(entry => ({
                visible: entry.isIntersecting,
                ratio: entry.intersectionRatio,
                rect: entry.intersectionRect,
                id: entry.target.id
            }))

            entries.forEach(entry => entry.isIntersecting && observer.unobserve(entry.target))

            callback(intersections)
        }

        return new window.IntersectionObserver(handler, options)
    }

    function intersectionHandler(intersections) {
        const idsToTrigger = intersections.filter(inter => inter.visible)
            .map(inter => ({
                compId: inter.id,
                action: 'screenIn'}))

        manager.trigger(idsToTrigger)
    }

    let observer = getObserver(intersectionHandler)

    function observeElement(el) {
        observer.observe(el)
    }

    function traverseModel() {
        Object.keys(model)
            .filter(id => Object.keys(model[id]).indexOf('screenIn') > -1) // eslint-disable-line lodash/prefer-includes
            .forEach(id => {
                const el = document.getElementById(id)
                if (el) {
                    observeElement(el)
                }
            })
    }

    function disconnectObserver() {
        if (observer) {
            observer.disconnect()
        }

        observer = null
    }

    return {
        start: traverseModel,
        stop: disconnectObserver
    }
}
